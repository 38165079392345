import React, { useState } from 'react'
import { Hidden } from '@material-ui/core'
import {
  Timer,
  CallMade,
  AppsRounded,
  ArrowBack,
  Dehaze,
  FormatListBulleted,
  LiveHelpOutlined,
  LiveHelp
} from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { Bell, Gift } from 'react-feather'
import { makeStyles } from '@material-ui/styles'
import { timeStyle } from "../../../pages/Dashboard/TimeSheets/ViewAndEdit/timeCalculator";
import { selectShowTimeTrackerWidget } from '../../../store/selectors'
import { showTimeTrackerWidget } from '../../../store/actions/dashboard.actions'
import UserMenu from './UserMenu'
import NotificationPopup from './NotificationPopup'
import News from './News'
import TimeTrackerWidget from '../TimeTrackerWidget'
import * as S from './styles'

interface Props {
  showSideMenu: boolean
  toggleDrawer: () => void
  toggleSidebar: () => void
}

const useStyles = makeStyles(() => ({
  dropdown: {
    "marginTop": 10,
    "marginRight": -10,
    '& .ant-dropdown-menu-item': {
      "padding": '8px 16px',
      '& svg': {
        width: 20,
        height: 20
      }
    }
  }
}));

const Header = ({ showSideMenu, toggleDrawer, toggleSidebar }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isShowTimeTrackerWidget = useSelector(selectShowTimeTrackerWidget)
  const [openNewsDrawer, setOpenNewsDrawer] = useState(false)

  const openTimeTrackWidget = () => {
    dispatch(showTimeTrackerWidget(true))
  }

  return (
    <S.Container>
      <Hidden mdUp>
        <S.IconButton onClick={toggleDrawer}>
          <Dehaze />
        </S.IconButton>
      </Hidden>
      <Hidden smDown>
        {
          showSideMenu
          ? <S.HideMenuButton onClick={toggleSidebar}>
              <ArrowBack />
            </S.HideMenuButton>
          : <S.IconButton onClick={toggleSidebar}>
              <Dehaze />
            </S.IconButton>
        }
      </Hidden>
      <S.TimeBox onClick={openTimeTrackWidget}>
        <Timer />
        <span>
          {timeStyle(Number(localStorage.getItem('second-reading'))).hour}
          :{timeStyle(Number(localStorage.getItem('second-reading'))).minute}
          :{timeStyle(Number(localStorage.getItem('second-reading'))).second}
        </span>
        <CallMade />
      </S.TimeBox>
      <S.RightMenu>
        <Hidden smDown>
          <S.IconButton onClick={() => setOpenNewsDrawer(!openNewsDrawer)}>
            <LiveHelp />
          </S.IconButton>
          <NotificationPopup />
          <S.IconButton>
            <Gift />
            <span className='badge'>9</span>
          </S.IconButton>
          <S.IconButton>
            <AppsRounded />
          </S.IconButton>
        </Hidden>
        <Hidden mdUp>
          <Dropdown
            overlay={
              <Menu className={classes.dropdown}>
                <Menu.Item icon={<LiveHelpOutlined fontSize='small' />} onClick={() => setOpenNewsDrawer(!openNewsDrawer)}>
                  News
                </Menu.Item>
                <Menu.Item icon={<Bell fontSize='small' />}>
                  <Link to='/dashboard/notifications'>Notifications</Link>
                </Menu.Item>
                <Menu.Item icon={<Gift fontSize='small' />}>
                  Gifts
                </Menu.Item>
              </Menu>
            }
            placement='bottomRight'
            trigger={['click']}
          >
            <S.IconButton><FormatListBulleted /></S.IconButton>
          </Dropdown>
        </Hidden>
        <UserMenu />
        <S.Divider />
        <S.MenuButton>
          E
        </S.MenuButton>
      </S.RightMenu>
      <News
        open={openNewsDrawer}
        setOpen={setOpenNewsDrawer}
      />
      {
        isShowTimeTrackerWidget && <TimeTrackerWidget />
      }
    </S.Container>
  )
}

export default Header;
