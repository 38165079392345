import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSchedule } from "../../../../store/selectors";
import DatePicker from "../../DatePicker";
import { Box, Grid } from "@material-ui/core";
import { FormikProps } from "formik";
import { IScheduleProps } from "./index";

const ScheduleDate = ({ ...props }: FormikProps<IScheduleProps>) => {
  const schedule = useSelector(getSchedule);
  const [date, setDate] = useState<Date>(new Date(props.values.date));

  useEffect(() => {
    props.setFieldValue('date', date);
  }, [date]);

  return (
    <Box mb={3}>
      <Grid item>
        <DatePicker label="Schedule Date" isCustom date={date} setDate={setDate} isDisabled={!!schedule} />
      </Grid>
    </Box>
  )
}
export default ScheduleDate;
