import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setNotifications } from "../../../store/actions/notification.action";
import { getNotifications } from "../../../store/selectors/notification.selector";
import { Dropdown } from 'antd'
import { Bell } from 'react-feather'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as S from './styles'
import * as NotificationApi from '../../../apis/notification.api';

const Container = styled.div`
  width: 350px;
  background: white;
  margin-top: 6px;
  margin-right: -20px;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.palette.dashboard.textPrimary};
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  .notification-list {
    .notification-item {
      color: ${props => props.theme.palette.dashboard.textPrimary};
      padding: 10px 20px;
      border-top: 1px solid ${props => props.theme.colors.gray100};

      &:last-child {
        border-bottom: 1px solid ${props => props.theme.colors.gray100};
      }
    }
  }

  a {
    color: ${props => props.theme.palette.dashboard.primary};
  }
`

const NotificationPopup = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);

  useEffect(() => {
    NotificationApi.getAll().then((res) => dispatch(setNotifications(res)));
  }, []);

  const notes = useMemo(() => {
    return notifications.filter((notification) => notification.checked);
  }, [notifications]);

  return (
    <Dropdown
      overlay={
        <Container>
          <div className='header'>
            {`Notifications(${notes.length})`}
          </div>
          <div className='notification-list'>
            {
              notes.map((note) => (
                <div key={note.id} className='notification-item'>
                  { note.detail }
                </div>
              ))
            }
          </div>
          <div className='footer'>
            <Link to='/dashboard/notifications'>See all notifications</Link>
          </div>
        </Container>
      }
      placement='bottomRight'
      trigger={['click']}
    >
      <S.IconButton>
        <Bell />
        <span className='badge'>{notes.length}</span>
      </S.IconButton>
    </Dropdown>
  )
}

export default NotificationPopup