import styled from "styled-components";
import { Box } from '@material-ui/core'

const ModalContainer = styled(Box)`
  min-width: 540px;
  .tab-content {
    padding-bottom: 30px;
    .ant-tabs {
      overflow: inherit;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: end;
    gap: 12px;
    margin-top: 2rem;
  }
`

const FormControl = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 10px;
  gap: 4px;

  .label {
    font-size: 11px;
    text-transform: uppercase;
    color: ${(props) =>
            props.error ? "red" : props.theme.palette.dashboard.textLight};
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .switch-label {
    font-size: 11px;
    color: ${(props) =>
            props.error ? "red" : props.theme.palette.dashboard.textLight};
    width: 200px;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  > div {
    width: 100%;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    flex-direction: column;

    .label {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
`;

export { FormControl, ModalContainer };
