import React, { useEffect, useRef, useState } from "react";

export const rowCounts = [10, 15, 20];

interface Props {
  rowCount: number,
  setRowCount: (value: number) => void,
}

const RowCount = (props: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const { rowCount, setRowCount } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const clickListener = (e: MouseEvent) => {
      let el: Node | null = e.target as Node;
      if (!el || !el.parentNode)
        return;

      while (el && el !== ref.current) {
        el = el.parentNode;
      }
      if (!el && isOpen)
        setIsOpen(false);
    };
    window.addEventListener("click", clickListener);
    return () => window.removeEventListener("click", clickListener);
  }, [isOpen]);

  return (
    <div
      style={{ position: 'relative' }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <h4 style={{ fontWeight: 600, cursor: "pointer" }}>{ rowCount }</h4>
      {
        isOpen && (
          <div
            style={{
              position: 'absolute',
              boxShadow: '0 2px 12px 6px rgba(47,83,151,0.1)',
              padding: '5px 10px',
              left: '-50%',
              background: 'white',
              borderRadius: '5px'
            }}
          >
            {
              rowCounts.map((count, index) => (
                <h4
                  key={index}
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => setRowCount(count)}
                >
                  { count }
                </h4>
              ))
            }
          </div>
        )
      }
    </div>
  )
}
export default RowCount;
