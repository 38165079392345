import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExpense } from "../../../store/selectors";
import { setExpense, setExpenses } from "../../../store/actions/expense.action";
import { Form, Formik, FormikProps } from "formik";
import { Tabs } from "../CommonStyled";
import { IDateRange } from "../DateRange";
import Modal from "../Modal";
import Button from "../Button";
import GeneralTab from "./GeneralTab";
import Property from "./Property";
import PayedDate from "./PayedDate";
import * as Yup from "yup";
import * as S from "./style";
import * as ExpenseApi from "../../../apis/expense.api";

const { TabPane } = Tabs;

export interface IProps {
  onClose: () => void;
  isOpen: boolean;
  searchValue: string;
  dateRange: IDateRange;
  setTotalCount: (value: number) => void;
}

export interface IExpenseInterface {
  payer: {
    value: string,
    label: string
  } | null;
  member: {
    value: string;
    label: string;
  } | null;
  project: {
    value: string
    label: string
  } | null;
  payedDate: Date;
  amount: number | null;
  category: string | null;
}

const AddExpenseModal = (props: IProps) => {
  const { onClose, isOpen, searchValue, dateRange, setTotalCount } = props;
  const dispatch = useDispatch();
  const expense = useSelector(getExpense);

  useEffect(() => {
    if (!isOpen) {
      dispatch(setExpense(null));
    }
  }, [isOpen]);

  const initialValues = useMemo(() => {
    return {
      payer: expense?.payer || null,
      member: expense?.member || null,
      payedDate: expense?.payedDate || new Date(),
      amount: expense?.amount || null,
      category: expense?.category || null,
      project: expense?.project || null,
    }
  }, [expense]);

  const formSchema = Yup.object().shape({
    payer: Yup.object().required(),
    member: Yup.object().required(),
    project: Yup.object().required(),
    amount: Yup.number().required(),
    category: Yup.string().required(),
    payedDate: Yup.date().required(),
  });

  const handleSubmit = (value: IExpenseInterface) => {
    if (expense) {
      const id = expense.id;
      ExpenseApi.update(id, value, searchValue, dateRange).then((res) => dispatch(setExpenses(res.data)));
    } else {
      ExpenseApi.create(value, searchValue, dateRange).then((res) => {
        dispatch(setExpenses(res.data));
        setTotalCount(res.count);
      });
    }
    onClose();
  };

  return (
    <Modal title="Add Expense" onClose={onClose} open={isOpen}>
      <S.ModalContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<IExpenseInterface>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className="tab-content">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="GENERAL" key="1">
                    <GeneralTab { ...props } />
                  </TabPane>
                  <TabPane tab="PROPERTY" key="2">
                    <Property { ...props } />
                  </TabPane>
                  <TabPane tab="PAYEDDATE" key="3">
                    <PayedDate { ...props } />
                  </TabPane>
                </Tabs>
              </div>
              <div className="modal-footer">
                <Button className="gray-outlined" onClick={onClose}>Cancel</Button>
                <Button
                  htmlType='submit'
                  className="primary-contained"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </S.ModalContainer>
    </Modal>
  )
}
export default AddExpenseModal;
