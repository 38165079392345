import React from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Dropdown, Menu } from 'antd'
import { MoreVert } from '@material-ui/icons'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'

import { IconButton } from '../styles'
import { getWidgets } from '../../../../store/selectors'
import { setWidgets } from '../../../../store/actions/dashboard.actions'

const Container = styled(Box)`
  width: 100%;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.gray100};
  border-radius: 3px;
  padding: 10px 20px 20px 20px;

  .card-body {
    text-align: center;

    img {
      max-width: 180px;
      margin: 25px 0;
    }

    p {
      font-size: 12px;
      color: ${props => props.theme.palette.dashboard.textLight};

      span {
        color: ${props => props.theme.colors.blue50};
        cursor: pointer;
      }
    }
  }

  .view-more {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 12px;
      color: ${props => props.theme.colors.blue50};
    }

    svg {
      color: ${props => props.theme.colors.blue50};
      font-size: 15px;
    }
  }
`

const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;

  .title {
    font-size: 12px;
    text-transform: uppercase;
    color: ${props => props.theme.palette.dashboard.textLight};
  }

  .sub-title {
    font-size: 10px;
    color: ${props => props.theme.palette.dashboard.textLight};
  }
`

const SortItem = SortableElement(props => <Container {...props} />)
const DragHandle = SortableHandle(props => <Header {...props} />)

const useStyles = makeStyles(() => ({
  dropdown: {
    '& .ant-dropdown-menu-item': {
      padding: '8px 16px'
    }
  }
}));

const Card = (props) => {
  const dispatch = useDispatch()
  const widgets = useSelector(getWidgets)
  const classes = useStyles()
  const { title, children, index } = props

  const handleRemove = (index: number) => {
    const largeWidgets = [...widgets.largeWidgets]
    largeWidgets.splice(index, 1)
    dispatch(setWidgets({...widgets, largeWidgets}))
  }

  return (
    <SortItem index={index}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <DragHandle>
          <span className='title'>{title}</span>
        </DragHandle>
        <Dropdown
          overlay={
            <Menu className={classes.dropdown}>
              <Menu.Item
                key={`remove${index}`}
                onClick={() => handleRemove(index)}
              >
                Remove
              </Menu.Item>
            </Menu>
          }
          placement='bottomRight'
          trigger={['click']}
        >
          <IconButton size='small'><MoreVert /></IconButton>
        </Dropdown>
      </Box>
      {children}
    </SortItem>
  )
}

export default Card