import * as XLSX from 'xlsx';

export class CSVService {
  static async export(content: Record<string, any[]>, type: string) {
    const sheets = {};
    Object.keys(content ?? {}).forEach(key => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(content[key]);
      sheets[key] = worksheet;
    });
    const workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: Object.keys(content ?? {}),
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: type as XLSX.BookType,
      type: 'array'
    });
    const blob: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    return blob;
  }
}
