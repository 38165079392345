import styled from 'styled-components'
import { Drawer as MuiDrawer, IconButton as MuiIconButton } from '@material-ui/core'

const TimeLabel = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  padding-bottom: 10px;
  color: ${props => props.theme.palette.dashboard.textDark};

  span {
    font-weight: 400;
    font-size: 20px;
    margin-left: 5px;
    color: ${props => props.theme.palette.dashboard.textPrimary};
  }
`

const IconButton = styled(MuiIconButton)`
  svg {
    color: ${props => props.theme.colors.blue50};
  }
`

const WeeklyTimesheetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(18, minmax(0, 1fr));
  min-width: 1000px;
  border: 2px solid ${props => props.theme.colors.gray100};
  border-right: none;

  .column {
    grid-column: span 2;

    &.name {
      grid-column: span 2;

      .item {
      }
    }

    &.total {
      grid-column: span 2;
    }

    &.today {
      background-color: #f4f6f9;

      h3, .day {
        color: ${props => props.theme.colors.blue50} !important;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      border: 2px solid ${props => props.theme.colors.gray100};
      border-top: none;
      border-left: none;
      color: ${props => props.theme.palette.dashboard.textPrimary};

      &.date-label {
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 30px;
          line-height: 36px;
          margin-right: 5px;
          color: ${props => props.theme.palette.dashboard.textDark};
        }

        .day {
          color: ${props => props.theme.palette.dashboard.textDark};
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          span {
            font-size: 12px;

            &.month {
              color: ${props => props.theme.colors.gray200};
            }
          }
        }
      }

      &.header {
        font-weight: bold;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
`

const CalendarTimesheetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(30, minmax(0, 1fr));
  border: 2px solid ${props => props.theme.colors.gray100};
  border-right: none;
  border-bottom: none;
  min-width: 1000px;

  .column {
    grid-column: span 2;

    &.name {
      grid-column: span 1;

      .item {
        border-right: none;
      }

      .time {
        border: none;
        align-items: flex-start;

        span {
          font-size: 12px;
          color: ${props => props.theme.palette.dashboard.textLight};
        }
      }
    }

    &.today {
      background-color: #f4f6f9;

      h3, .day {
        color: ${props => props.theme.colors.blue50} !important;
      }

      .item:not(.date-label) {
        background-color: #fcf8e3;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      border: 2px solid ${props => props.theme.colors.gray100};
      border-top: none;
      border-left: none;

      &.date-label {
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 20px;
          line-height: 36px;
          margin-right: 5px;
          color: ${props => props.theme.palette.dashboard.textDark};
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          span {
            font-size: 10px;

            &.month {
              color: ${props => props.theme.colors.gray200};
            }
          }
        }
      }

      &.header {
        font-weight: bold;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
`

const Drawer = styled(MuiDrawer)`
  width: 345px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: 345px;
    margin-top: 45px;
    overflow: visible;
    box-shadow: -3px -5px 15px 2px rgba(103, 99, 99, 0.2);
  }

  ${props => props.theme.breakpoints.down(500)} {
    width: 70%;

    .MuiDrawer-paper {
      width: 70%;
    }
  }
`

const DrawerContainer = styled.div`
  position: relative;
  padding: 25px 30px;
  background: white;

  .ant-tabs {
    overflow: visible;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      font-size: 16px;
    }
  }

  ${props => props.theme.breakpoints.down(500)} {
    padding: 15px;
  }
`

export {
  Drawer,
  IconButton,
  WeeklyTimesheetContainer,
  CalendarTimesheetContainer,
  DrawerContainer,
  TimeLabel
}