import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from "react-redux";
import { getMembers, getSchedules } from "../../../../store/selectors";
import { addDays } from 'date-fns'
import { getMemberNameById, scheduleContentByDate } from "./helper";
import { Box } from '@material-ui/core'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import { IDateRange } from '../../../../components/Dashboard/DateRange'
import { IFilterProps } from "./index";
import ScheduleAction from "./ScheduleAction";
import moment from 'moment-timezone'
import * as S from './styles'
import _ from "lodash";

interface Props {
  dateRange: IDateRange,
  filterParams: IFilterProps,
  unScheduled: boolean,
  setUnScheduled: (value: boolean) => void;
  setOpenModal: (value: boolean) => void;
}

const Members = (props: Props) => {
  const {dateRange, filterParams, unScheduled, setUnScheduled, setOpenModal} = props;
  const ref = useRef<HTMLInputElement>(null);
  const [actionOpen, setActionOpen] = useState<{ id: string, index: number } | null>(null);
  const schedules = useSelector(getSchedules);
  const members = useSelector(getMembers);

  const handleOpenActionModal = (memberId: string, index: number) => {
    if (!actionOpen) {
      setActionOpen({
        id: memberId,
        index
      });
    } else {
      setActionOpen(null);
    }
  };

  useEffect(() => {
    if (!actionOpen) {
      return;
    }

    const clickListener = (e: MouseEvent) => {
      let el: Node | null = e.target as Node;
      if (!el || !el.parentNode)
        return;

      while (el && el !== ref.current) {
        el = el.parentNode;
      }
      if (!el && actionOpen)
        setActionOpen(null);
    };
    window.addEventListener("click", clickListener);
    return () => window.removeEventListener("click", clickListener);
  }, [actionOpen]);

  const showMembers = useMemo(() => {
    if (unScheduled) {
      const memberHavingSchedule = _.map(schedules, 'member');
      return filterParams.members.filter((memberId) => !_.map(memberHavingSchedule, 'id').includes(memberId));
    }
    return filterParams.members;
  }, [unScheduled, filterParams.members]);

  return (
    <>
      <S.MemberScheduleContainer ref={ref}>
        <div className='column members'>
          <div className='item header'>
            <Box display='flex' flexDirection='column' width='100%'>
              <h4>Members</h4>
              <Box display='flex' justifyContent='space-between'>
                <span className='label'>Show unscheduled</span>
                <Switch
                  size='small'
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onClick={(e) => setUnScheduled(e)}
                />
              </Box>
            </Box>
          </div>
          {
            showMembers.map((id, index) => (
              <div key={index} className='item'>
                {getMemberNameById(id, members)}
              </div>
            ))
          }
        </div>
        {
          new Array(7).fill(0).map((_, index) => {
            const date = addDays(dateRange.startDate, index)
            const isToday = moment().isSame(date, 'day') ? 'today' : ''
            return (
              <React.Fragment key={index}>
                <div className={`column date ${isToday}`}>
                  <div className='item date-label'>
                    <h3>{moment(date).format('DD')}</h3>
                    <div>
                      <span className='day'>{moment(date).format('ddd')}</span>
                      <span className='month'>{moment(date).format('MMM')}</span>
                    </div>
                  </div>
                  {
                    showMembers.map((memberId, idx) => (
                      <div
                        key={idx}
                        className='item'
                        onClick={() => handleOpenActionModal(memberId, index)}
                      >
                        {scheduleContentByDate(date, memberId, schedules)}
                        {
                          actionOpen && actionOpen.id === memberId && actionOpen.index === index && (
                            <ScheduleAction
                              setOpenModal={setOpenModal}
                              filterParams={filterParams}
                              memberId={memberId}
                              date={date}
                            />
                          )
                        }
                      </div>
                    ))
                  }
                </div>
              </React.Fragment>
            )
          })
        }
      </S.MemberScheduleContainer>
      {
        showMembers.length === 0 && (
          <S.noInfo>
            No Schedules
          </S.noInfo>
        )
      }
    </>
  )
}

export default Members