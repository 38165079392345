import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setNotification, setNotifications } from "../../../store/actions/notification.action";
import { getNotifications } from "../../../store/selectors/notification.selector";
import { Box } from '@material-ui/core';
import { Checkbox } from "antd";
import Button from '../../../components/Dashboard/Button';
import TableActionMenu from "../../../components/Dashboard/TableActionMenu";
import NotificationModal from "../../../components/Dashboard/Notification";
import moment from "moment-timezone";
import * as S from './styles';
import * as NotificationApi from '../../../apis/notification.api';
import _ from "lodash";

export interface ICheckNote {
  id: string;
  checked: boolean;
}

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const [checkNote, setCheckNote] = useState<ICheckNote[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (notifications.length > 0) {
      setCheckNote(notifications.map((item) => ({
        id: item.id,
        checked: item.checked,
      })));
    }
  }, [notifications]);

  const handleCheck = (value: boolean, id: string) => {
    setCheckNote((prevState: ICheckNote[]) => {
      return prevState.map((item) => (
        item.id === id ? {
          ...item,
          checked: value,
        } : {
          ...item
        }
      ));
    });
  };

  const handleShowNotifications = () => {
    NotificationApi.checkedState(checkNote).then((res) => dispatch(setNotifications(res)));
  };

  const handleEditRow = async (id: string) => {
    await NotificationApi.getById(id).then((res) => dispatch(setNotification(res)));
    setOpenModal(true);
  };

  const handleDeleteRow = (id: string) => {
    NotificationApi.deleteById(id).then((res) => dispatch(setNotifications(res)));
  };

  return (
    <>
      <S.Container>
        <Box mb={2}>
          <h1>Notifications</h1>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <h3>Needs your attention</h3>
          <Button
            className='primary-contained'
            onClick={() => setOpenModal(true)}
          >
            Add notification
          </Button>
        </Box>
        {
          notifications.length > 0 && (
            notifications.map((note) => (
              <S.NotificationItem key={note.id}>
                <Checkbox
                  checked={checkNote.filter((item) => item.id === note.id)[0]?.checked}
                  onChange={(e) => handleCheck(e.target.checked, note.id)}
                />
                <div className='content'>
                  { note.detail }
                </div>
                <span className='time'>{moment(note.date).fromNow()}</span>
                <TableActionMenu
                  row={note}
                  handleEditRow={handleEditRow}
                  handleDeleteRow={handleDeleteRow}
                />
              </S.NotificationItem>
            ))
          )
        }
        <Box display='flex' justifyContent='center' mt={3}>
          {
            _.map(checkNote, 'checked').includes(true) ? (
              <Button
                onClick={handleShowNotifications}
              >
                Show selected notifications
              </Button>
            ) : (
              <Button disabled>No more notifications to show</Button>
            )
          }
        </Box>
      </S.Container>
      <NotificationModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  )
}

export default Notifications;
