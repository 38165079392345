import Http from './http'
import { IRegisterFormData } from '../shared/interfaces'

export const login = (email: string, password: string) => {
  return Http.post('/auth/login', { email, password });
}

export const register = (data: IRegisterFormData) => {
  return Http.post('/auth/register', data);
}

export const getAdmin = () => {
  return Http.get('/auth/admin');
}
