import React, { FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Dropdown, Menu, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'

const useStyles = makeStyles(() => ({
  button: {
    border: '1px solid #e3e8ef !important',
    borderRadius: '5px !important',
    padding: '3px 11px !important',
    backgroundColor: 'white',
    color: 'lightgray'
  }
}));

interface IProps {
  row: any;
  handleEditRow: (value: string) => void;
  handleDeleteRow: (value: string) => void;
}

const TableActionMenu: FC<IProps> = ({row, handleEditRow, handleDeleteRow}) => {
  const classes = useStyles();

  return (
    <Dropdown
      key={row.key}
      overlay={
        <Menu>
          <Menu.Item
            key='edit'
            onClick={() => handleEditRow(row.id)}
          >
            Edit
          </Menu.Item>
          <Menu.Item
            key='delete'
            onClick={() => handleDeleteRow(row.id)}
          >
            Delete
          </Menu.Item>
        </Menu>
      }
      placement='bottomRight'
      trigger={['click']}
    >
      <Button
        className={classes.button}
      >
        Actions <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default TableActionMenu
