import Http from "./http";
import {IAccountInterface} from "../store/actions/member.action";

export const getAll = () => {
  return Http.get('/user/all');
}

export const fetchMe = () => {
  return Http.get('/user/me');
}

export const updateAccount = (value) => {
  return Http.patch('/user/account', value);
}
