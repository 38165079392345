import React from "react";
import { FormikProps } from "formik";
import { IExpenseInterface } from "./index";
import Select, { Option } from '../../../components/Dashboard/Select'
import { Grid } from "@material-ui/core";
import InputGroup from "../../Common/InputGroup";

const categoryOptions = [
  {
    value: 'uncategorized',
    label: 'Uncategorized'
  },
  {
    value: 'travel',
    label: 'Travel'
  },
  {
    value: 'equipment',
    label: 'Equipment'
  }
];

const Property = ({ ...props }: FormikProps<IExpenseInterface>) => {
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <InputGroup
          label="Amount"
          name='amount'
          placeholder='amount'
          addonAfter="$"
          { ...props }
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name='category'
          label='Category'
          placeholder='select a category'
          value={props.values.category}
          onChange={(e) => props.setFieldValue('category', e)}
        >
          {
            categoryOptions.map((option) => (
              <Option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </Option>
            ))
          }
        </Select>
      </Grid>
    </Grid>
  )
}
export default Property;
