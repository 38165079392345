import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

export class PdfService {
  static async export({ elementId, name }: { elementId: string, name: string }) {
    const pdf = new jsPDF();

    let w: number;
    let h: number;

    const html = document.getElementById(elementId) as HTMLElement;
    const image = (await html2canvas(html)).toDataURL('image/png');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const { width, height } = pdf.getImageProperties(image);
    w = pageWidth;
    h = (w / width) * height;
    pdf.addImage(image, 'png', 0, 0, w, h);
    pdf.save(`${name}.pdf`);
  };
}
