import moment from "moment";
import { IDateRange } from "../../../../components/Dashboard/DateRange";
import { ITimeSheetInterface } from "../../../../store/actions/timesheet.action";
import _ from "lodash";

export const timeStyle = (value: number) => {
  const time = moment.duration(value, "seconds");

  return {
    hour: (time.hours() + time.days() * 24).toString(),
    minute: time.minutes().toString().padStart(2, '0'),
    second: time.seconds().toString().padStart(2, '0'),
  }
}

export const divideByDateRange = (range: IDateRange) => {
  const hourRange = (range.endDate.getTime() - range.startDate.getTime()) / 3600000;
  if (hourRange >= 20 * 24)
    return 'Monthly';
  else if (hourRange > 24)
    return 'Weekly'
  else
    return 'Daily'
}

export const totalWorkTimeByDate = (date: Date, timeSheets: ITimeSheetInterface[], projectId?: string) => {
  const filterByDate = timeSheets.filter(
    item => {
      if (projectId) {
        return moment(item.startDate).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY') && item.project.id === projectId
      } else {
        return moment(item.startDate).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY');
      }
    }
  );
  const totalTimeByDate = _.sum(_.map(filterByDate, 'duration'));
  return `${timeStyle(totalTimeByDate).hour}:${timeStyle(totalTimeByDate).minute}:${timeStyle(totalTimeByDate).second}`
}

export const WeeklyWorkTime = (timeSheets: ITimeSheetInterface[], projectId?: string) => {
  const filterByDate = timeSheets.filter(
    item => {
      if (projectId) {
        return item.project.id === projectId;
      } else {
        return timeSheets;
      }
    }
  );
  const totalTime = _.sum(_.map(filterByDate, 'duration'));
  return `${timeStyle(totalTime).hour}:${timeStyle(totalTime).minute}:${timeStyle(totalTime).second}`
}
