import React, { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { MemberNamesWithSchedule, scheduleContentByDate } from "./helper";
import { IScheduleInterface } from "../../../../store/actions/schedule.action";
import { IDateRange } from '../../../../components/Dashboard/DateRange';
import moment from 'moment-timezone';
import * as ScheduleApi from "../../../../apis/schedule.api";
import * as S from './styles';

interface Props {
  dateRange: IDateRange;
  rowCount: number;
}

const WeekSchedule = (props: Props) => {
  const { dateRange, rowCount } = props;
  const [weeklySchedules, setWeeklySchedules] = useState<IScheduleInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    ScheduleApi.weeklySchedules(dateRange).then((res) => (
      setWeeklySchedules(res),
      setLoading(true)
    ));
  }, [dateRange]);

  return (
    <>
      <S.WeekScheduleContainer>
        <div className='column members'>
          <div className='item'>
            <h4>Members</h4>
          </div>
          {
            MemberNamesWithSchedule(weeklySchedules, rowCount).names.map((name, index) => (
              <div
                key={index}
                className='item'
              >
                {name}
              </div>
            ))
          }
        </div>
        {
          new Array(7).fill(0).map((_, index) => {
            const date = addDays(dateRange.startDate, index)
            const isToday = moment().isSame(date, 'day') ? 'today' : ''
            return (
              <React.Fragment key={index}>
                <div className={`column date ${isToday}`}>
                  <div className='item date-label'>
                    <h3>{moment(date).format('DD')}</h3>
                    <div>
                      <span className='day'>{moment(date).format('ddd')}</span>
                      <span className='month'>{moment(date).format('MMM')}</span>
                    </div>
                  </div>
                  {
                    MemberNamesWithSchedule(weeklySchedules, rowCount).ids.map((memberId, idx) => (
                      <div
                        key={idx}
                        className='item'
                      >
                        {scheduleContentByDate(date, memberId, weeklySchedules)}
                      </div>
                    ))
                  }
                </div>
              </React.Fragment>
            )
          })
        }
      </S.WeekScheduleContainer>
      {
        weeklySchedules.length === 0 && loading && (
          <S.noInfo>
            No Schedules
          </S.noInfo>
        )
      }
    </>
  )
}

export default WeekSchedule;
