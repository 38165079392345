import React, { useEffect, useState } from 'react'
import { addDays } from 'date-fns'
import { useSelector } from "react-redux";
import { getTimeSheets } from "../../../../store/selectors";
import { totalWorkTimeByDate, WeeklyWorkTime } from "./timeCalculator";
import { IDateRange } from '../../../../components/Dashboard/DateRange'
import moment from 'moment-timezone'
import * as S from './styles'
import * as ProjectApi from '../../../../apis/project.api';

interface Props {
  dateRange: IDateRange;
  developer: string | null;
}

const WeeklyTimesheet = (props: Props) => {
  const { dateRange, developer } = props;
  const timeSheets = useSelector(getTimeSheets);
  const [assignedProjects, setAssignedProject] = useState<Array<{value: string, label: string}>>([]);

  useEffect(() => {
    if (developer) {
      ProjectApi.getProjectsByDeveloper(developer).then((res) => setAssignedProject(res));
    }
  }, [developer]);

  return (
    <S.WeeklyTimesheetContainer>
      <div className='column name'>
        <div className='item' >
          Weekly
        </div>
        <div className='item'>
          All projects
        </div>
        {
          assignedProjects.map(project => (
            <div className='item'>
              {project.label}
            </div>
          ))
        }
      </div>
      {
        new Array(7).fill(0).map((_, index) => {
          const date = addDays(dateRange.startDate, index)
          const isToday = moment().isSame(date, 'day') ? 'today' : ''
          return (
            <React.Fragment key={index}>
              <div className={`column date ${isToday}`}>
                <div className='item date-label'>
                  <h3>{moment(date).format('DD')}</h3>
                  <div>
                    <span className='day'>{moment(date).format('ddd')}</span>
                    <span className='month'>{moment(date).format('MMM')}</span>
                  </div>
                </div>
                <div className='item'>
                  {totalWorkTimeByDate(date, timeSheets)}
                </div>
                {
                  assignedProjects.map((project) => (
                    <div className='item'>
                      {totalWorkTimeByDate(date, timeSheets, project.value)}
                    </div>
                  ))
                }
              </div>
            </React.Fragment>
          )
        })
      }
      <div className='column total'>
        <div className='item header'>
          Total
        </div>
        <div className='item'>
          {WeeklyWorkTime(timeSheets)}
        </div>
        {
          assignedProjects.map(project => (
            <div className='item'>
              {WeeklyWorkTime(timeSheets, project.value)}
            </div>
          ))
        }
      </div>
    </S.WeeklyTimesheetContainer>
  )
}

export default WeeklyTimesheet