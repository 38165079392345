import * as types from '../actionTypes'

export interface INotificationInterface {
  id: string,
  detail: string,
  date: Date,
  checked: boolean,
}

export const setNotifications = (values: INotificationInterface[]) => {
  return {
    type: types.SET_NOTIFICATIONS,
    payload: values,
  }
}

export const setNotification = (value: INotificationInterface | null) => {
  return {
    type: types.SET_NOTIFICATION,
    payload: value,
  }
}
