import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { setFetchMe } from "../../../store/actions/member.action";
import { Box } from '@material-ui/core'
import ManageWidgets from './ManageWdigets'
import SmallWidgetList from './SmallWidgetList'
import BigWidgetList from './BigWidgetList'
import * as S from './styles'
import moment from "moment-timezone";

const DefaultPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFetchMe(null));
  }, []);

  return (
    <S.Container>
      <Box mb={2}>
        <h1>Dashboard page</h1>
        <span className='date-text'>
          {`${moment(new Date()).startOf('week').format('ddd, MMM DD, YYYY')}
          ~
          ${moment(new Date()).endOf('week').format('ddd, MMM DD, YYYY')}`}
        </span>
      </Box>
      <Box display='flex' justifyContent='flex-end' width='100%' mb={2}>
        <ManageWidgets />
      </Box>
      <SmallWidgetList />
      <BigWidgetList />
    </S.Container>
  )
}

export default DefaultPage