import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule} from "../../../../store/selectors";
import { setSchedule, setSchedules } from "../../../../store/actions/schedule.action";
import { Form, Formik, FormikProps } from "formik";
import { Tabs } from "../../CommonStyled";
import { IFilterProps } from "../../../../pages/Dashboard/Schedules/Calendar";
import ScheduleContent from "./ScheduleContent";
import ScheduleDate from "./ScheduleDate";
import Modal from "../../Modal";
import Button from "../../Button";
import * as ScheduleApi from "../../../../apis/schedule.api";
import * as S from "./style";
import * as Yup from "yup";

const { TabPane } = Tabs;

export interface IScheduleProps {
  id?: string,
  member: {
    value: string,
    label: string
  } | null,
  content: string,
  date: Date
}

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  filterParams: IFilterProps;
}

const AddScheduleModal: FC<IProps> = ({
  onClose,
  isOpen,
  filterParams
}) => {
  const dispatch = useDispatch();
  const schedule = useSelector(getSchedule);

  useEffect(() => {
    if (!isOpen) {
      dispatch(setSchedule(null));
    }
  }, [isOpen]);

  const formSchema = Yup.object().shape({
    member: Yup.object().required(),
    content: Yup.string().required(),
    date: Yup.string().required()
  });

  const initialValues = useMemo(() => {
    return {
      member: schedule?.member || null,
      content: schedule?.content || '',
      date: schedule?.date || new Date(),
    }
  }, [schedule]);

  const handleSubmit = (value: IScheduleProps) => {
    if (!schedule) {
      ScheduleApi.create({
        value,
        filterParams
      }).then((res) => dispatch(setSchedules(res)));
    } else {
      ScheduleApi.update({
        id: schedule.id,
        value,
        filterParams,
      }).then((res) => dispatch(setSchedules(res)));
    }
    onClose();
  }

  return (
    <Modal
      title='Add schedule'
      onClose={onClose}
      open={isOpen}
    >
      <S.ModalContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={formSchema}
        >
          {(props: FormikProps<IScheduleProps>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className="tab-content">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Content" key="1">
                    <ScheduleContent { ...props } />
                  </TabPane>
                  <TabPane tab="Date" key="2">
                    <ScheduleDate { ...props } />
                  </TabPane>
                </Tabs>
              </div>
              <div className="modal-footer">
                <Button
                  className="gray-outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  htmlType='submit'
                  className="primary-contained"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </S.ModalContainer>
    </Modal>
  )
}
export default AddScheduleModal;
