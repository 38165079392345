import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from "react-redux";
import { setMembers } from "../../../../store/actions/member.action";
import { getMembers } from "../../../../store/selectors";
import { setSchedules } from "../../../../store/actions/schedule.action";
import { Box } from '@material-ui/core'
import { TabContainer } from '../../../../components/Dashboard/CommonStyled'
import { Info } from '@material-ui/icons'
import Select, { Option } from '../../../../components/Dashboard/Select'
import Tab from '../../../../components/Dashboard/Tab'
import DateRange, { IDateRange } from '../../../../components/Dashboard/DateRange'
import Button from '../../../../components/Dashboard/Button'
import Members from './Memebers'
import WeekSchedule from './Week'
import AddScheduleModal from "../../../../components/Dashboard/Schedule/CreateModal";
import TodaySchedule from "./Today";
import RowCount, { rowCounts } from "./RowCount";
import moment from 'moment'
import * as S from './styles'
import * as UserApi from "../../../../apis/user.api";
import * as ScheduleApi from "../../../../apis/schedule.api";

const useStyles = makeStyles(() => ({
  multiSelect: {
    '& .ant-select-selector': {
      paddingLeft: '5px !important'
    }
  }
}))

const tabs = [
  {
    id: '1',
    label: 'Members'
  },
  {
    id: '2',
    label: 'Week'
  },
  {
    id: '3',
    label: 'Today'
  },
]

function tagRender(props) {
  const { label, closable, onClose } = props;

  return (
    <S.Tag closable={closable} onClose={onClose}>
      {label}
    </S.Tag>
  );
}

export interface IFilterProps {
  members: string[];
  dateRange: IDateRange;
}

const Calendar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const members = useSelector(getMembers);
  const [activeTabId, setActiveTabId] = useState('1');
  const [unScheduled, setUnScheduled] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(rowCounts[0]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  });
  const [filterParams, setFilterParams] = useState<IFilterProps>({
    members: [],
    dateRange,
  });

  useEffect(() => {
    UserApi.getAll().then((res) => dispatch(setMembers(res)));
  }, []);

  useMemo(() => {
    setFilterParams({
      ...filterParams,
      dateRange
    });
  }, [dateRange]);

  useEffect(() => {
    ScheduleApi.getSchedulesByFilterParams(filterParams).then((res) => dispatch(setSchedules(res)));
  }, [filterParams]);

  return (
    <S.Container>
      <Box mb={2}>
        <h1>Schedules</h1>
      </Box>
      <TabContainer>
        <Tab
          tabs={tabs}
          activeTabId={activeTabId}
          onChangeTab={(id: string) => setActiveTabId(id)}
        />
      </TabContainer>
      <Box className='filter'>
        <Box className='date-range' display='flex' alignItems='center'>
          <DateRange
            showActionButtons={true}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <Box ml='10px'>
            <Button
              className='outlined'
              onClick={() => setDateRange({
                ...dateRange,
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
              })}
            >
              Today
            </Button>
          </Box>
        </Box>
        <Box display='flex'>
          <Box width='300px'>
            <Select label='Timezone' defaultValue='my-time-zone'>
              <Option value='my-time-zone'>My time zone (Europe - Berlin)</Option>
            </Select>
          </Box>
          <Box width='300px' ml='10px'>
            <Select
              label='Members'
              mode='multiple'
              showArrow
              tagRender={tagRender}
              className={classes.multiSelect}
              placeholder='Select members'
              value={filterParams.members}
              onChange={(e) => setFilterParams({ ...filterParams, members: [...e] })}
            >
              {
                members.map((member) => (
                  <Option key={member.id} value={member.id as string}>{member.name}</Option>
                ))
              }
            </Select>
          </Box>
        </Box>
      </Box>
      <Box display='flex' mt={1.5}>
        <Button
          className='primary-contained'
          style={{ marginLeft: 'auto' }}
          onClick={() => setOpenModal(true)}
        >
          Create Schedule
        </Button>
      </Box>
      {
        activeTabId === '1' &&
          <Box mt={5} overflow='auto'>
            <Members
              dateRange={dateRange}
              filterParams={filterParams}
              unScheduled={unScheduled}
              setUnScheduled={setUnScheduled}
              setOpenModal={setOpenModal}
            />
          </Box>
      }
      {
        activeTabId === '2' &&
          <Box overflow='auto'>
            <Box display='flex' justifyContent='flex-end'>
              <div className='max-member'>
                <Info />
                <span>A max of&nbsp;</span>
                <RowCount rowCount={rowCount} setRowCount={setRowCount} />
                <span>&nbsp;members are shown in this view</span>
              </div>
            </Box>
            <Box mt={5}>
              <WeekSchedule rowCount={rowCount} dateRange={dateRange}/>
            </Box>
          </Box>
      }
      {
        activeTabId === '3' &&
        <>
          <Box display='flex' justifyContent='flex-end'>
            <div className='max-member'>
              <Info />
              <span>A max of&nbsp;</span>
              <RowCount rowCount={rowCount} setRowCount={setRowCount} />
              <span>&nbsp;members are shown in this view</span>
            </div>
          </Box>
          <Box mt={5}>
            <TodaySchedule rowCount={rowCount} />
          </Box>
        </>
      }
      <AddScheduleModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        filterParams={filterParams}
      />
    </S.Container>
  )
}

export default Calendar;
