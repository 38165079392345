import { IFilterProps } from "../pages/Dashboard/Schedules/Calendar";
import { IScheduleProps } from "../components/Dashboard/Schedule/CreateModal";
import { IDateRange } from "../components/Dashboard/DateRange";
import Http from "./http";

export const create = ({ value, filterParams }: { value: IScheduleProps, filterParams: IFilterProps }) => {
  return Http.post('/schedule/create', { value, filterParams });
}

export const getSchedulesByFilterParams = (filterParams: IFilterProps) => {
  return Http.get('/schedule', filterParams);
}

export const weeklySchedules = (dateRange: IDateRange) => {
  return Http.get('/schedule/weekly', dateRange);
}

export const todaySchedules = ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
  return Http.get('/schedule/today', { startDate, endDate });
}

export const update = ({ id, value, filterParams }: { id: string | undefined, value: IScheduleProps, filterParams: IFilterProps }) => {
  return Http.patch(`/schedule/update/${id}`, { value, filterParams });
}

export const deleteById = ({ id, filterParams }: { id: string, filterParams: IFilterProps }) => {
  return Http.delete(`/schedule/${id}`, { filterParams });
}
