import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {setSchedule, setSchedules} from "../../../../store/actions/schedule.action";
import { getMembers, getSchedules } from "../../../../store/selectors";
import { scheduleProps } from "./helper";
import { IFilterProps } from "./index";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { Tooltip } from "../../Settings/styles";
import styled from "styled-components";
import * as ScheduleApi from "../../../../apis/schedule.api";

const ActionModal = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  width: 4rem;
  padding: 0.7rem 0.2rem;
  top: 5px;
  border-radius: 0.2rem;

  .edit {
    &:hover {
      color: blue;
    }
  }

  .delete {
    &:hover {
      color: red;
    }
  }
`
interface Props {
  setOpenModal: (value: boolean) => void;
  memberId: string;
  date: Date;
  filterParams: IFilterProps;
}

const ScheduleAction = (props: Props) => {
  const dispatch = useDispatch();
  const schedules = useSelector(getSchedules);
  const members = useSelector(getMembers);
  const { setOpenModal, memberId, date, filterParams } = props;

  const handleEditSchedule = () => {
    setOpenModal(true);
    dispatch(setSchedule(scheduleProps(memberId, date, schedules, members)));
  };

  const handleDeleteSchedule = () => {
    const scheduleId = scheduleProps(memberId, date, schedules, members).id;

    if (scheduleId !== 'empty') {
      ScheduleApi.deleteById({
        id: scheduleId,
        filterParams,
      }).then((res) => dispatch(setSchedules(res)));
    }
  }

  return (
    <ActionModal>
      <Tooltip title='edit'>
        <EditOutlined
          fontSize='small'
          className='edit'
          onClick={handleEditSchedule}
        />
      </Tooltip>
      <Tooltip title='delete'>
        <DeleteOutlined
          fontSize='small'
          className='delete'
          onClick={handleDeleteSchedule}
        />
      </Tooltip>
    </ActionModal>
  )
}
export default ScheduleAction;
