import http from "./http";
import { IExpenseInterface } from "../components/Dashboard/Expense";
import { IDateRange } from "../components/Dashboard/DateRange";

export const create = (value: IExpenseInterface, searchValue: string, dateRange: IDateRange) => {
  return http.post('/expense/create', { value, searchValue, dateRange });
}

export const getAll = ({ searchValue, dateRange }: { searchValue: string, dateRange: IDateRange }) => {
  return http.get('/expense/all', { searchValue, dateRange });
}

export const getById = (id: string) => {
  return http.get(`/expense/${id}`);
}

export const update = (id: string, value: IExpenseInterface, searchValue: string, dateRange: IDateRange) => {
  return http.patch(`/expense/${id}`, { value, searchValue, dateRange });
}

export const applyStatus = ({ids, status}: { ids: string[], status: string }) => {
  return http.patch('/expense/status', { ids, status });
}

export const deleteById = (id: string, searchValue: string, dateRange: IDateRange) => {
  return http.delete(`/expense/${id}`, { searchValue, dateRange });
}
