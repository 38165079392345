import { IMemberInterface, ITeamInterface } from "../../components/Dashboard/Team/AddTeamModal";
import { IOrganization } from "../../shared/interfaces";
import * as types from '../actionTypes';

export interface IAccountInterface {
  id?: string;
  name: string;
  email: string;
  organization: IOrganization;
  team: ITeamInterface;
  role: string;
  checked: boolean;
}

export const setMembers = (value: IMemberInterface[]) => {
  return {
    type: types.SET_MEMBERS,
    payload: value
  }
}

export const setFetchMe = (value: IAccountInterface | null) => {
  return {
    type: types.SET_FETCH_ME,
    payload: value
  }
}
