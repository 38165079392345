import * as types from '../actionTypes'
import { INotificationInterface } from "../actions/notification.action";

const initialState: {
  notifications: INotificationInterface[];
  notification: INotificationInterface | null
} = {
  notifications: [],
  notification: null,
}

const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case types.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    default:
      return state;
  }
}

export default notificationReducer;
