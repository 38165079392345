import * as types from '../actionTypes';
import { IMemberInterface } from "../../components/Dashboard/Team/AddTeamModal";
import { IScheduleProps } from "../../components/Dashboard/Schedule/CreateModal";

export interface IScheduleInterface {
  id: string,
  member: IMemberInterface,
  date: Date,
  content: string,
}

export const setSchedules = (value: IScheduleInterface[]) => {
  return {
    type: types.SET_SCHEDULES,
    payload: value,
  }
}

export const setSchedule = (value: IScheduleProps | null) => {
  return {
    type: types.SET_SCHEDULE,
    payload: value,
  }
}
