import * as types from "../actionTypes";
import { IMemberInterface } from "../../components/Dashboard/Team/AddTeamModal";
import { IProjectInterface } from "../../components/Dashboard/Poject/AddProjectModal";

export interface ITimeSheetInterface {
  id: string,
  developer: IMemberInterface,
  project: IProjectInterface,
  startDate: Date,
  endDate: Date,
  duration: number,
  note: string,
  progressing: boolean,
  createdAt: Date,
}

export const setTimeSheets = (value: ITimeSheetInterface[]) => {
  return {
    type: types.SET_TIMESHEETS,
    payload: value
  }
}

export const setTimeSheet = (value: any) => {
  return {
    type: types.SET_TIMESHEET,
    payload: value
  }
}
