import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from "react-redux";
import { Box } from '@material-ui/core'
import { AppPage, Tabs } from '../../../../components/Dashboard/CommonStyled'
import { Container } from './styles'
import { makeStyles } from '@material-ui/styles'
import { setTimeSheets } from "../../../../store/actions/timesheet.action";
import DateRange, { IDateRange } from '../../../../components/Dashboard/DateRange'
import Input from '../../../../components/Dashboard/Input'
import Button from '../../../../components/Dashboard/Button'
import ApprovalTable from './ApprovalTable'
import clsx from 'clsx'
import moment from 'moment'
import * as TimeSheetApi from '../../../../apis/timesheet.api';

const { TabPane } = Tabs

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 360
  },
  dateRange: {
    '& .show-date': {
      width: 360
    }
  },
  button: {
    padding: '7px 22px !important',
    borderColor: '#18d06b !important',
    backgroundColor: '#18d06b !important',
    marginLeft: 10,
  }
}));

export interface IFilterProps {
  date: IDateRange;
  member: string;
}

const Approvals = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [memberName, setMemberName] = useState<string>('');
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  });
  const [filterParams, setFilterParams] = useState<IFilterProps>({
    date: dateRange,
    member: ''
  });

  useEffect(() => {
    TimeSheetApi.getTimeSheetsApproval(filterParams).then((res) => dispatch(setTimeSheets(res)));
  }, [filterParams]);

  useMemo(() => {
    setFilterParams({
      ...filterParams,
      date: dateRange
    })
  }, [dateRange]);

  const handleApply = () => {
    setFilterParams({
      ...filterParams,
      member: memberName,
    });
  };

  return (
    <AppPage>
      <Container>
        <Box mb={2}>
          <h1>Timesheet approvals</h1>
        </Box>
        <Box className='date-range'>
          <DateRange
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </Box>
        <Box
          display='flex'
          mt={2}
          alignItems='flex-end'
          className='member-select'
        >
          <Input
            label='All Members'
            onChange={(e) => setMemberName(e.target.value)}
          />
          <Button
            onClick={handleApply}
            className={clsx(classes.button, 'primary-contained')}
          >
            Apply
          </Button>
        </Box>
        <Box mt={4}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="OPEN (6)" key="1">
              <Box pt={3}>
                <ApprovalTable filterParams={filterParams} />
              </Box>
            </TabPane>
            <TabPane tab="SUBMITTED (0)" key="2">

            </TabPane>
            <TabPane tab="APPROVED (0)" key="3">

            </TabPane>
            <TabPane tab="DENIED (0)" key="4">

            </TabPane>
            <TabPane tab="ALL (0)" key="5">

            </TabPane>
          </Tabs>
        </Box>
      </Container>
    </AppPage>
  )
}

export default Approvals