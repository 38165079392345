import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "../../CommonStyled";

import { Form, Formik, FormikProps } from "formik";
import { setProject, setProjects } from "../../../../store/actions/project.actions";
import { getProject } from "../../../../store/selectors";
import { setMembers } from "../../../../store/actions/member.action";
import { setClients } from "../../../../store/actions/client.action";
import * as S from "./style";
import * as Yup from "yup";
import GeneralTab from "./GeneralTab";
import MembersTab from "./MembersTab";
import BudgetAndLimitTab from "./BudgetAndLimitTab";
import TeamTab from "./TeamTab";
import Modal from "../../Modal";
import Button from "../../Button";
import * as ProjectApi from "../../../../apis/project.api";
import * as UserApi from "../../../../apis/user.api";
import * as ClientApi from "../../../../apis/client.api";

const { TabPane } = Tabs;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  searchValue: string;
}

export interface IProjectInterface {
  id?: string;
  name: string;
  billable: boolean;
  disableActivity: boolean;
  disableIdleTime: boolean;
  client: {
    value: string,
    label: string
  } | null;
  managers: string[];
  developers: string[];
  viewers: string[];
  budgetType: string;
  basedOn: string;
  cost: string;
  notifyAt: string;
  reset: string;
  startDate: Date;
  teams: string[];
}

const AddProjectModal = (props: Props) => {
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const { onClose, isOpen, searchValue } = props;

  useEffect(() => {
    if (!isOpen) {
      dispatch(setProject(null));
    }
  }, [isOpen]);

  useEffect(() => {
    UserApi.getAll().then((res) =>　dispatch(setMembers(res)));
    ClientApi.getAllClients().then((res) => dispatch(setClients(res)));
  }, []);

  const formSchema = Yup.object().shape({
    name: Yup.string().required("can't be blank"),
    billable: Yup.boolean().required(),
    disableActivity: Yup.boolean().required(),
    disableIdleTime: Yup.boolean().required(),
    client: Yup.object().required(),
    managers: Yup.array(),
    developers: Yup.array(),
    viewers: Yup.array(),
    budgetType: Yup.string(),
    basedOn: Yup.string(),
    cost: Yup.string(),
    notifyAt: Yup.string(),
    reset: Yup.string(),
    startDate: Yup.date(),
    teams: Yup.array(),
  });

  const initialValue = useMemo(() => {
    return {
      name: project?.name || '',
      billable: project?.billable || false,
      disableActivity: project?.disableActivity || false,
      disableIdleTime: project?.disableIdleTime || false,
      client: project?.client || null,
      managers: project?.managers || [],
      developers: project?.developers || [],
      viewers: project?.viewers || [],
      budgetType: project?.budgetType || '',
      basedOn: project?.basedOn || '',
      cost: project?.cost || '',
      notifyAt: project?.notifyAt || '',
      reset: project?.reset || '',
      startDate: project?.startDate || new Date(),
      teams: project?.teams || [],
    };
  }, [project]);

  const handleSubmit = async (value: IProjectInterface) => {
    if (project?.id) {
      await ProjectApi.update({
        id: project.id,
        data: {
          ...value,
          client: value.client?.value as any,
        }
      });
    } else {
      await ProjectApi.create({
        ...value,
        client: value.client?.value as any,
      });
    }
    await ProjectApi.getAll(searchValue).then((res) => dispatch(setProjects(res)));
    onClose();
  };

  return (
    <Modal title="Add project" onClose={onClose} open={isOpen}>
      <S.ModalContainer>
        <Formik
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validationSchema={formSchema}
        >
          {(props: FormikProps<IProjectInterface>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className="tab-content">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="GENERAL" key="1">
                    <GeneralTab { ...props } />
                  </TabPane>
                  <TabPane tab="BUDGET & LIMITS" key="2">
                    <BudgetAndLimitTab { ...props } />
                  </TabPane>
                  <TabPane tab="TEAMS" key="3">
                    <TeamTab { ...props } />
                  </TabPane>
                  <TabPane tab="MEMBERS" key="4">
                    <MembersTab data={project} { ...props } />
                  </TabPane>
                </Tabs>
              </div>
              <div className="modal-footer">
                <Button className="gray-outlined" onClick={onClose}>Cancel</Button>
                <Button
                  htmlType='submit'
                  className="primary-contained"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </S.ModalContainer>
    </Modal>
  );
};

export default AddProjectModal;
