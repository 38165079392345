import React from 'react'
import { addDays } from 'date-fns'
import { useSelector } from "react-redux";
import { getTimeSheets } from "../../../../store/selectors";
import { totalWorkTimeByDate } from "./timeCalculator";
import { IDateRange } from '../../../../components/Dashboard/DateRange'
import moment from 'moment-timezone'
import * as S from './styles'

interface Props {
  dateRange: IDateRange
}

const CalendarTimesheet = (props: Props) => {
  const { dateRange } = props;
  const timeSheets = useSelector(getTimeSheets);

  return (
    <S.CalendarTimesheetContainer>
      {
        new Array(30).fill(0).map((_, index) => {
          const date = addDays(dateRange.startDate, index)
          const isToday = moment().isSame(date, 'day') ? 'today' : ''
          return (
            <React.Fragment key={index}>
              <div className={`column date ${isToday}`}>
                <div className='item date-label'>
                  <h3>{moment(date).format('DD')}</h3>
                  <div>
                    <span className='day'>{moment(date).format('ddd')}</span>
                    <span className='month'>{moment(date).format('MMM')}</span>
                  </div>
                </div>
                {
                  new Array(1).fill(0).map((_, index) => (
                    <div className='item' style={{borderBottom: '2px solid #e3e8ef' }} key={index}>
                      {totalWorkTimeByDate(date, timeSheets)}
                    </div>
                  ))
                }
              </div>
            </React.Fragment>
          )
        })
      }
    </S.CalendarTimesheetContainer>
  )
}

export default CalendarTimesheet
