import moment from "moment";
import { IMemberInterface } from "../../../../components/Dashboard/Team/AddTeamModal";
import { IScheduleInterface } from "../../../../store/actions/schedule.action";
import _ from "lodash";

export const scheduleContentByDate = (date: Date, memberId: string, schedules: IScheduleInterface[]) => {
  const schedule = _.filter(schedules, schedule =>
    schedule.member.id === memberId && (moment(new Date(date)).format('DD/MM/YYYY') === moment(new Date(schedule.date)).format('DD/MM/YYYY'))
  );

  return schedule[0]?.content;
}

export const getMemberNameById = (id: string, members: IMemberInterface[]) => {
  return _.filter(members, member => member.id === id)[0].name;
}

export const scheduleProps = (memberId: string, date: Date, schedules: IScheduleInterface[], members: IMemberInterface[]) => {
  const schedule = _.filter(schedules, schedule =>
    schedule.member.id === memberId && (moment(new Date(date)).format('DD/MM/YYYY') === moment(new Date(schedule.date)).format('DD/MM/YYYY'))
  );

  if (schedule.length === 0) {
    const member = _.filter(members, member => member.id === memberId)[0];
    return {
      id: 'empty',
      member: {
        value: memberId,
        label: member.name,
      },
      content: '',
      date,
    }
  } else {
    return {
      id: schedule[0].id,
      member: {
        value: schedule[0].member.id as string,
        label: schedule[0].member.name,
      },
      content: schedule[0].content,
      date: schedule[0].date,
    }
  }
}

export const MemberNamesWithSchedule = (schedules: IScheduleInterface[], rowCount: number) => {
  return {
    ids: _.uniq(_.map(_.map(schedules, 'member'), 'id')).slice(0, rowCount) as string[],
    names: _.uniq(_.map(_.map(schedules, 'member'), 'name')).slice(0, rowCount) as string[],
  }
}
