import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccount, getMembers } from "../../../store/selectors";
import { FormikProps } from "formik";
import { IExpenseInterface } from "./index";
import CustomSelect from "../../Common/Select/Select";
import * as S from "../Poject/AddProjectModal/style";
import * as ProjectApi from "../../../apis/project.api";

const GeneralTab = ({ ...props }: FormikProps<IExpenseInterface>) => {
  const account = useSelector(getAccount);
  const members = useSelector(getMembers);
  const [projectsOption, setProjectsOption] = useState<Array<{ value: string, label: string }>>([]);

  const payer = useMemo(() => {
    return [
      {
        value: account?.id,
        label: account?.name
      }
    ];
  }, [account]);

  const membersOption = useMemo(() => {
    return members.map((member) => (
      {
        value: member.id,
        label: member.name
      }
    ));
  }, [members]);

  useEffect(() => {
    if (props.values.member) {
      ProjectApi.getProjectsByDeveloper(props.values.member.value).then((res) => setProjectsOption(res));
    } else {
      setProjectsOption([]);
    }
  }, [props.values.member]);

  return (
    <>
      <S.FormControl>
        <CustomSelect
          label="Payer"
          name="payer"
          value={props.values.payer}
          onChange={(e) => props.setFieldValue('payer', { value: e?.value, label: e?.label })}
          placeholder="Select a payer"
          options={payer}
          styles={{
            control: (style) => ({ ...style, width: "100%" })
          }}
          onClear={() => props.setFieldValue('payer', null)}
        />
      </S.FormControl>
      <S.FormControl>
        <CustomSelect
          label="Member"
          name="member"
          value={props.values.member}
          onChange={(e) => props.setFieldValue('member', { value: e?.value, label: e?.label })}
          placeholder="Select a member"
          options={membersOption}
          styles={{
            control: (style) => ({ ...style, width: "100%" })
          }}
          onClear={() => props.setFieldValue('member', null)}
        />
      </S.FormControl>
      <S.FormControl>
        <CustomSelect
          label="Project"
          name="project"
          value={props.values.project}
          onChange={(e) => props.setFieldValue('project', { value: e?.value, label: e?.label })}
          placeholder="Select a project"
          options={projectsOption}
          styles={{
            control: (style) => ({ ...style, width: "100%" })
          }}
          onClear={() => props.setFieldValue('project', null)}
        />
      </S.FormControl>
    </>
  )
}
export default GeneralTab;
