import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setInvoiceDraft } from "../../../../../store/actions/invoice.action";
import { getInvoice } from "../../../../../store/selectors/invoice.selector";
import { setClients } from "../../../../../store/actions/client.action";
import { getClients } from "../../../../../store/selectors";
import { Box, FormControl, Grid } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { Form, Formik, FormikProps } from "formik";
import { Select as ASelect } from "antd";
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import DatePicker from '../../../../../components/Dashboard/DatePicker'
import Input from '../../../../../components/Dashboard/Input'
import CreatInvoiceTable from './CreateInvoiceTable'
import Button from '../../../../../components/Dashboard/Button'
import Select from "../../../../../components/Dashboard/Select";
import * as S from './styles'
import * as Yup from "yup";
import * as ClientApi from "../../../../../apis/client.api";
import * as InvoiceApi from "../../../../../apis/invoice.api";
import * as AuthApi from "../../../../../apis/auth.api";

const { Option } = ASelect;

const useStyles = makeStyles(() => ({
  button: {
    marginRight: '10px',
  },
  draftButton: {
    borderColor: '#18d06b !important',
    backgroundColor: '#18d06b !important',
  }
}));

export interface IInvoiceInterface {
  id?: string;
  payer: string | null;
  client: string | null;
  issueDate?: Date;
  dueDate?: Date;
  invoiceNumber: number;
  poNumber: number;
  description: string;
  total: number;
  tax1: number;
  tax2: number;
  discount: number;
  grandTotal?: number;
  status?: string;
}

const CreateInvoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const invoice = useSelector(getInvoice);
  const clients = useSelector(getClients);
  const [issueDate, setIssueDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [addItem, setAddItem] = useState<boolean>(false);
  const [generateLine, setGenerateLine] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);

  useEffect(() => {
    AuthApi.getAdmin().then((res) => setUsers(res));
    ClientApi.getAllClients().then((res) => dispatch(setClients(res)));
  }, []);

  useEffect(() => {
    if (invoice && invoice.issueDate && invoice.dueDate) {
      setIssueDate(new Date(invoice?.issueDate));
      setDueDate(new Date(invoice.dueDate));
    }
  }, [invoice]);

  const invoiceSchema = Yup.object().shape({
    payer: Yup.string().required('can`t be blank'),
    client: Yup.string().required('can`t be blank'),
    invoiceNumber: Yup.number(),
    poNumber: Yup.number(),
    description: Yup.string(),
    total: Yup.number(),
    tax1: Yup.number(),
    tax2: Yup.number(),
    discount: Yup.number(),
  });

  const initialValues = useMemo(() => {
    if (invoice) {
      setAddItem(true);
    }

    return {
      payer: invoice?.payer || null,
      client: invoice?.client || null,
      invoiceNumber: invoice?.invoiceNumber || 0,
      poNumber: invoice?.poNumber || 0,
      description: invoice?.description || '',
      total: invoice?.total || 0,
      tax1: invoice?.tax1 || 0,
      tax2: invoice?.tax2 || 0,
      discount: invoice?.discount || 0,
    };
  }, [invoice]);

  const handleSaveInvoice = async (value: IInvoiceInterface) => {
    if (value.payer) {
      if (invoice?.id) {
        await InvoiceApi.update({
          id: invoice.id,
          data: {
            ...value,
            issueDate,
            dueDate,
            grandTotal: handleGrandTotal(value),
          }
        });
      } else {
        await InvoiceApi.create({
          ...value,
          issueDate,
          dueDate,
          grandTotal: handleGrandTotal(value),
        });
      }
      history.push('/dashboard/financials/invoices');
    }
  };

  const handleSaveAsDraft = (value: IInvoiceInterface) => {
    setGenerateLine(true);
    dispatch(setInvoiceDraft(value));
  };

  const handleGrandTotal = (value: IInvoiceInterface) => {
    return value.total * (1 - value.tax1 / 100) * (1 - value.tax2 / 100) * (1 - value.discount / 100);
  };

  return (
    <S.Container>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSaveInvoice}
        validationSchema={invoiceSchema}
      >
        {(props: FormikProps<IInvoiceInterface>) => (
          <Form onSubmit={props.handleSubmit}>
            <Box mb={1}>
              <h1>New Invoice</h1>
            </Box>
            <Grid container>
              <Grid item xl={5} lg={12} xs={12}>
                <FormControl
                  style={{ width: '50%' }}
                >
                  <Select
                    name='payer'
                    label='From'
                    placeholder='select a payer'
                    value={props.values.payer}
                    onChange={(e) => props.setFieldValue('payer', e)}
                  >
                    {users.length > 0 && users.map((user) => (
                      <Option
                        key={user.id}
                        value={user.id}
                      >
                        {user.name}
                      </Option>
                    ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={5} lg={12} xs={12}>
                <Box className='form-control' mb={2}>
                  <FormControl
                    style={{ width: '50%' }}
                  >
                    <Select
                      name='client'
                      label='Client'
                      placeholder='select a client'
                      value={props.values.client}
                      onChange={(e) => props.setFieldValue('client', e)}
                    >
                      {clients.length > 0 && clients.map((client) => (
                        <Option
                          key={client.id}
                          value={client.id}
                        >
                          {client.name}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box display='flex' mb={2}>
                  <Box className='form-control' mr={['15px', '30px']}>
                    <span className='label'>Issue Date*</span>
                    <DatePicker
                      date={issueDate}
                      setDate={setIssueDate}
                    />
                  </Box>
                  <Box className='form-control'>
                    <span className='label'>Due Date</span>
                    <DatePicker
                      date={dueDate}
                      setDate={setDueDate}
                    />
                  </Box>
                </Box>
                <Box display='flex' mb={2}>
                  <Box className='form-control' mr={['15px', '30px']}>
                    <Input
                      name='invoiceNumber'
                      label='Invoice Number*'
                      type='number'
                      value={props.values.invoiceNumber}
                      onChange={(e) => props.setFieldValue('invoiceNumber', e.target.value)}
                    />
                  </Box>
                  <Box className='form-control'>
                    <Input
                      name='poNumber'
                      label="Po Number"
                      type='number'
                      value={props.values.poNumber}
                      onChange={(e) => props.setFieldValue('poNumber', e.target.value)}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box mb={2}>
              {
                generateLine && (
                  <CreatInvoiceTable />
                )
              }
            </Box>
            <Grid container>
              <Grid item xs={12} lg={12} xl={6} >
                <Box className='add-btn-group' display='flex' alignItems='center' mb={2} mt={[3, 0]}>
                  <Button
                    onClick={() => setAddItem(true)}
                    className={clsx('primary-contained', classes.button)}
                  >
                    Add item
                  </Button>
                  <Button
                    onClick={() => setGenerateLine(!generateLine)}
                    className={classes.button}
                  >
                    Generate line items
                  </Button>
                  <InfoOutlined fontSize='small' />
                </Box>
                {
                  addItem && (
                    <Box className='form-control' pr={[0, 5]} mb={2}>
                      <span className='label'>Notes (shown on invoices)</span>
                      <S.StyledTextArea
                        name='description'
                        rows={4}
                        placeholder='Enter notes to client'
                        value={props.values.description}
                        onChange={(e) => props.setFieldValue('description', e.target.value)}
                      />
                    </Box>
                  )
                }
              </Grid>
              <Grid item xs={12} lg={12} xl={6}>
                <Box display='flex' justifyContent='flex-end' width='100%' pr={[0, '25px']}>
                  {
                    addItem && (
                      <S.InfoList>
                        <Box className='info-item'>
                          <p className='label'>Total($)</p>
                          <Box className='content'>
                            <Input
                              name='total'
                              type='number'
                              placeholder='Enter total payment'
                              value={props.values.total}
                              onChange={(e) => props.setFieldValue('total', e.target.value)}
                            />
                          </Box>
                        </Box>
                        <Box className='info-item'>
                          <p className='label'>Tax 1(%)</p>
                          <Box className='content'>
                            <Input
                              name='tax1'
                              type='number'
                              placeholder='Enter tax %'
                              value={props.values.tax1}
                              onChange={(e) => props.setFieldValue('tax1', e.target.value)}
                            />
                          </Box>
                        </Box>
                        <Box className='info-item'>
                          <p className='label'>Tax 2(%)</p>
                          <Box className='content'>
                            <Input
                              name='tax2'
                              type='number'
                              placeholder='Enter tax %'
                              value={props.values.tax2}
                              onChange={(e) => props.setFieldValue('tax2', e.target.value)}
                            />
                          </Box>
                        </Box>
                        <Box className='info-item'>
                          <p className='label'>Discount(%)</p>
                          <Box className='content'>
                            <Input
                              name='discount'
                              type='number'
                              placeholder='Enter discount %'
                              value={props.values.discount}
                              onChange={(e) => props.setFieldValue('discount', e.target.value)}
                            />
                          </Box>
                        </Box>
                        <Box className='info-item'>
                          <p className='label'>Grand total($)</p>
                          <Box
                            className='content'
                          >
                            {handleGrandTotal(props.values)}
                          </Box>
                        </Box>
                      </S.InfoList>
                    )
                  }
                </Box>
                <Box className='button-group' display='flex' justifyContent='flex-end' mt={3}>
                  <Button className={classes.button} onClick={() => history.push('/dashboard/financials/invoices')}>Cancel</Button>
                  <Button
                    className={clsx(classes.button, classes.draftButton, 'primary-contained')}
                    onClick={() => handleSaveAsDraft(props.values)}
                  >
                    Save as draft
                  </Button>
                  <Button
                    disabled={!props.isValid}
                    className={clsx(classes.button, 'primary-contained')}
                    onClick={() => handleSaveInvoice(props.values)}
                  >
                    Save and send
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </S.Container>
  )
}

export default CreateInvoice;
