import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "../../../store/selectors";
import { setTeams } from "../../../store/actions/team.action";
import { CSVService } from "../../../service/csv.service";
import { PdfService } from "../../../service/pdf.service";
import { saveAs } from "file-saver";
import { Box } from '@material-ui/core'
import { Search } from "@material-ui/icons";
import { Tooltip } from "antd";
import { Container } from "./style";
import TeamTable from './TeamTable'
import Input from "../../../components/Dashboard/Input";
import Button from "../../../components/Dashboard/Button";
import AddTeamModal from "../../../components/Dashboard/Team/AddTeamModal";
import Select, { Option } from "../../../components/Dashboard/Select";
import * as TeamApi from "../../../apis/team.api";
import _ from "lodash";

const Team = () => {
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    TeamApi.getAll(searchValue).then((res) => dispatch(setTeams(res)));
  }, [searchValue]);

  const excelData = useMemo(() => {
    return teams.map((team: any) => (
      {
        ...team,
        managers: _.map(team.managers, 'name').join(','),
        members: _.map(team.members, 'name').join(','),
        projects: team.projects.join(',')
      }
    ));
  }, [teams]);

  const handleExportProject = (value:　string) => {
    switch (value) {
      case 'csv': {
        return CSVService.export({ excelData }, 'csv').then((res) => {
          saveAs(res, `Team.csv`);
        });
      }
      case 'pdf': {
        return PdfService.export({
          elementId: 'print-pdf',
          name: 'Team'
        })
      }
      default:
        return;
    }
  };

  return (
    <Container>
      <Box mb={2}>
        <h1>Teams</h1>
      </Box>
      <Box mt={4}>
        <Box dir="row" pt={3} pb={5} pl={1} className="search-wrapper">
          <Input
            className="search"
            prefix={<Search />}
            placeholder="Search teams"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Box className="actions-row">
            <Box mr={3}>
              <Select
                placeholder="Export"
                onChange={(e) => handleExportProject(e)}
              >
                <Option value="csv">CSV</Option>
                <Option value="pdf">PDF</Option>
              </Select>
            </Box>
            <Tooltip
              className="tooltip"
              title="Add new team to the organization"
              placement="left"
            >
              <Button
                className="primary-contained"
                onClick={() => setOpenModal(true)}
              >
                Add team
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box id='print-pdf' pt={3}>
          <TeamTable
            searchValue={searchValue}
            setOpenModal={setOpenModal}
          />
        </Box>
      </Box>
      <AddTeamModal
        onClose={() => setOpenModal(false)}
        open={openModal}
        searchValue={searchValue}
      />
    </Container>
  )
}

export default Team;
