import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExpenses } from "../../../../store/selectors";
import { setExpense, setExpenses } from "../../../../store/actions/expense.action";
import { amountStyle, categoryItem, paymentStatus, stringAvatar } from "./helper";
import { IDateRange } from "../../../../components/Dashboard/DateRange";
import { TableContainer } from "../../../../components/Dashboard/CommonStyled";
import { Box } from "@material-ui/core";
import { Name } from "../../Team/TeamTable";
import {Avatar, Table} from "antd";
import TableActionMenu from "../../../../components/Dashboard/TableActionMenu";
import moment from "moment-timezone";
import * as ExpenseApi from '../../../../apis/expense.api';

interface IProps {
  searchValue: string;
  dateRange: IDateRange;
  setSelectedRow: (value: string[]) => void;
  setOpenModal: (value: boolean) => void;
  setTotalCount: (value: number) => void;
}

const ExpenseTable = (props: IProps) => {
  const { searchValue, dateRange, setSelectedRow, setOpenModal, setTotalCount } = props;
  const dispatch = useDispatch();
  const expenses = useSelector(getExpenses);

  const rowSelection = {
    onChange: (row) => {
      setSelectedRow(row);
    }
  };

  const columns = [
    {
      title: 'Member',
      dataIndex: 'member',
      render: (name: string) => (
        <Name>
          <div className='avatar'>
            {name[0]}
          </div>
          {name}
        </Name>
      )
    },
    {
      title: 'Date',
      dataIndex: 'payedDate',
      render: (date: Date) => (
        <div>{moment(date).format('ddd, MMM DD, YYYY')}</div>
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value: number) => (
        <div>{amountStyle(value)}</div>
      )
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (category: string) => (
        <Box
          display='inline-block'
          border='2px solid'
          px='7px'
          py='2px'
          borderRadius='5px'
          style={categoryItem(category)?.style}
        >
          {categoryItem(category)?.text}
        </Box>
      )
    },
    {
      title: 'Project',
      dataIndex: 'project',
      render: (name: string) => (
        <Avatar
          {...stringAvatar(name)}
        />
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => (
        <Box
          display='inline-block'
          py='8px'
          px='13px'
          borderRadius='5px'
          style={paymentStatus(status)?.style}
        >
          {paymentStatus(status)?.text}
        </Box>
      )
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row) => <TableActionMenu row={row} handleDeleteRow={handleDeleteRow} handleEditRow={handleEditRow} />
    }
  ];

  const tableData = useMemo(() => {
    return expenses.map((item) => (
      {
        ...item,
        key: item.id,
        member: item.member.name,
        project: item.project.name,
      }
    ));
  }, [expenses]);

  const handleDeleteRow = (id: string) => {
    ExpenseApi.deleteById(id, searchValue, dateRange).then((res) => {
      dispatch(setExpenses(res.data));
      setTotalCount(res.count);
    });
  };

  const handleEditRow = async (id: string) => {
    await ExpenseApi.getById(id).then((res) => dispatch(setExpense(res)));
    setOpenModal(true);
  };

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{x: 'max-content'}}
        rowSelection={rowSelection}
      />
      <Box mt={3}>
        <p>
          Showing { tableData.length } members
        </p>
      </Box>
    </TableContainer>
  )
}
export default ExpenseTable;
