import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { FormikProps } from "formik";
import { INotificationProps } from "./index";
import DatePicker from "../DatePicker";

const CreatedDate = ({ ...props }: FormikProps<INotificationProps>) => {
  const [date, setDate] = useState<Date>(new Date(props.values.date));

  useEffect(() => {
    props.setFieldValue('date', date);
  }, [date]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePicker label="Created date" isCustom date={date} setDate={setDate}/>
        </Grid>
      </Grid>
    </Box>
  )
}
export default CreatedDate;
