import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotifications } from "../../../store/actions/notification.action";
import { getNotification } from "../../../store/selectors/notification.selector";
import { Form, Formik, FormikProps } from "formik";
import { Tabs} from "../CommonStyled";
import Modal from "../Modal";
import Button from "../Button";
import DetailNote from "./DetailNote";
import CreatedDate from "./CreatedDate";
import * as NotificationApi from "../../../apis/notification.api";
import * as Yup from "yup";
import * as S from "./styles";

const { TabPane } = Tabs;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface INotificationProps {
  detail: string,
  date: Date,
}

const NotificationModal = ({ isOpen, onClose }: IProps) => {
  const dispatch = useDispatch();
  const notification = useSelector(getNotification);

  const initialValues = useMemo(() => {
    return {
      detail: notification?.detail || '',
      date: notification?.date || new Date(),
    }
  }, [notification]);

  const formSchema = Yup.object().shape({
    detail: Yup.string().required("can't be blank"),
    date: Yup.date().required("can't be blank")
  });

  const handleSubmit = (value: INotificationProps) => {
    if (notification) {
      NotificationApi.updateById({
        id: notification.id,
        value
      }).then((res) => dispatch(setNotifications(res)));
    } else {
      NotificationApi.create({
        ...value,
        checked: false,
      }).then((res) => dispatch(setNotifications(res)));
    }
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose} title="Add notification">
      <S.ModalContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<INotificationProps>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className="tab-content">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="NOTIFICATION" key="1">
                    <DetailNote { ...props } />
                  </TabPane>
                  <TabPane tab="CREATED DATE" key="2">
                    <CreatedDate { ...props } />
                  </TabPane>
                </Tabs>
              </div>
              <div className="modal-footer">
                <Button className="gray-outlined" onClick={onClose}>Cancel</Button>
                <Button
                  htmlType='submit'
                  className="primary-contained"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </S.ModalContainer>
    </Modal>
  )
}
export default NotificationModal;
