import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { FormikProps } from "formik";
import { IExpenseInterface } from "./index";
import DatePicker from "../DatePicker";

const PayedDate = ({ ...props }: FormikProps<IExpenseInterface>) => {
  const [date, setDate] = useState<Date>(new Date(props.values.payedDate));

  useEffect(() => {
    props.setFieldValue('payedDate', date);
  }, [date]);

  return (
    <Box mb={3}>
      <Grid item xs={12}>
        <DatePicker label="Payed date" isCustom date={date} setDate={setDate}/>
      </Grid>
    </Box>
  )
}
export default PayedDate;
