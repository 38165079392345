import * as types from '../actionTypes'
import { IScheduleInterface } from "../actions/schedule.action";
import { IScheduleProps } from "../../components/Dashboard/Schedule/CreateModal";

const initialState: {
  schedules: IScheduleInterface[];
  schedule: IScheduleProps | null;
} = {
  schedules: [],
  schedule: null,
}

const scheduleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_SCHEDULES:
      return {
        ...state,
        schedules: action.payload
      };
    case types.SET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload
      }
    default:
      return state;
  }
}

export default scheduleReducer;
