import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getMembers, getSchedule } from "../../../../store/selectors";
import { IScheduleProps } from "./index";
import { FormikProps } from "formik";
import TextArea from "../../../Common/TextArea";
import CustomSelect from "../../../Common/Select/Select";
import * as S from "./style";

const ScheduleContent = ({ ...props }: FormikProps<IScheduleProps>) => {
  const members = useSelector(getMembers);
  const schedule = useSelector(getSchedule);

  const membersOption = useMemo(() => {
    return members.map((member) => {
      return {
        value: member.id,
        label: member.name
      }
    })
  }, [members]);

  const handleClearMember = () => {
    if (!schedule) {
      props.setFieldValue('member', null);
    }
  }

  return (
    <>
      <S.FormControl>
        <CustomSelect
          name='member'
          label="Choose one member with a schedule"
          placeholder='selector a schedule'
          options={membersOption}
          styles={{
            control: (style) => ({
              ...style, width: "100%"
            })
          }}
          value={props.values.member}
          onChange={(e) => props.setFieldValue('member', { value: e?.value, label: e?.label })}
          onClear={handleClearMember}
          isDisabled={!!schedule}
        />
      </S.FormControl>
      <S.FormControl>
        <span className='label' style={{ alignItems: 'flex-start' }}>
          Schedule Content
        </span>
        <TextArea
          name='content'
          placeholder='You can add your schedule'
          value={props.values.content}
          onChange={(e) => props.setFieldValue('content', e.target.value)}
        />
      </S.FormControl>
    </>
  )
}
export default ScheduleContent;
