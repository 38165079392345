import { IMemberInterface } from "../../components/Dashboard/Team/AddTeamModal";
import { IProjectInterface } from "../../components/Dashboard/Poject/AddProjectModal";
import * as types from '../actionTypes';

export interface IExpense {
  id: string,
  payer: IMemberInterface;
  member: IMemberInterface;
  project: IProjectInterface;
  amount: number;
  category: string;
  payedDate: Date;
}

export interface IUpdateExpense {
  id: string,
  payer: {
    value: string,
    label: string,
  };
  member: {
    value: string,
    label: string,
  };
  project: {
    value: string,
    label: string,
  };
  amount: number;
  category: string;
  payedDate: Date;
}

export const setExpenses = (value: IExpense[]) => {
  return {
    type: types.SET_EXPENSES,
    payload: value
  }
};

export const setExpense = (value: IUpdateExpense | null) => {
  return {
    type: types.SET_EXPENSE,
    payload: value,
  }
};
