import React from 'react'
import { useSelector } from "react-redux";
import { getTimeSheets } from "../../store/selectors";
import { Tooltip } from "antd";
import styled from 'styled-components'
import moment from "moment";

const Container = styled.div`
  .basic-line {
    position: relative;
    width: 100%;
    height: 17px;
    border-radius: 10px;
    background-color: #dfe6f1;
  }
  .date-line {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
  }
`

const SheetItem = styled.div<{ position: number, width: number }>`
  position: absolute;
  left: ${(props) => props.position}%;
  width: ${(props) => props.width}%;
  background: gray;
  opacity: 0.6;
  height: 100%;
`

interface IProgressItem {
  key: string
  from: Date
  to: Date
}

const TimeLine = ({ dateRange }) => {
  const timeSheets = useSelector(getTimeSheets);

  const TimeSheet = ({ key, from, to }: IProgressItem) => {
    const total: number = dateRange.endDate.getTime() - dateRange.startDate.getTime();
    const startDate = moment(dateRange.startDate).startOf('day').toDate().getTime();
    const position = (new Date(from).getTime() - startDate) / total;
    const width = (new Date(to).getTime() - new Date(from).getTime()) / total;

    return (
      <Tooltip key={key} title={`${moment(from).format('MM/DD HH:mm:ss')}` + '~' + `${moment(to).format('MM/DD HH:mm:ss')}`}>
        <SheetItem position={position * 100} width={width * 100}></SheetItem>
      </Tooltip>
    )
  };

  return (
    <Container>
      <div className='basic-line'>
        {
          timeSheets.map((item) => (
            TimeSheet({
              key: item.id,
              from: item.startDate,
              to: item.endDate
            })
          ))
        }
      </div>
      <div className='date-line'>
        <span>{moment(dateRange.startDate).startOf('day').format('MM/DD HH:mm:ss')}</span>
        <span>{moment(dateRange.endDate).endOf('day').format('MM/DD HH:mm:ss')}</span>
      </div>
    </Container>
  )
}

export default TimeLine;
