import { IExpense, IUpdateExpense } from "../actions/expense.action";
import * as types from "../actionTypes";

const initialValue: {
  expenses: IExpense[];
  expense: IUpdateExpense | null;
  loading: boolean;
} = {
  expenses: [],
  expense: null,
  loading: true,
}

const expenseReducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case types.SET_EXPENSES: {
      return {
        ...state,
        loading: false,
        expenses: action.payload
      }
    }
    case types.SET_EXPENSE: {
      return {
        ...state,
        expense: action.payload
      }
    }
    default:
      return state;
  }
}
export default expenseReducer;
