import styled from 'styled-components'
import { Tag as ATag } from 'antd'

import { AppPage } from '../../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  position: relative;

  .max-member {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: ${props => props.theme.palette.dashboard.textPrimary};

    svg {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  .no-events {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    background-color: #eeeeee;
  }

  .filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 16px;

    ${props => props.theme.breakpoints.down('lg')} {
      flex-direction: column;
      align-items: flex-start;

      > div:last-child {
        margin-top: 20px;
      }
    }

    ${props => props.theme.breakpoints.down('sm')} {
      > div {
        width: 100%;
      }

      .date-range {
        > div:last-child {
          flex: 1;

          button {
            width: 100%;
          }
        }
      }

      > div:last-child {
        > div {
          flex: 1;
        }
      }
    }

    ${props => props.theme.breakpoints.down(500)} {
      > div {
        width: 100%;
        flex-direction: column;
      }

      .date-range {
        align-items: flex-start;

        > div:first-child {
          width: 100%;

          .show-date {
            flex: 1;
          }
        }

        > div:last-child {
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
        }
      }

      > div:last-child {
        > div {
          width: 100%;
          margin-left: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
`

const Tag = styled(ATag)`
  &.ant-tag {
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 25px;
    background-color: white;
    border-left: 4px solid #826dff;
    margin-right: 3px;
  }
`

const noInfo = styled.div`
    width: 100%;
    border: 2px solid ${props => props.theme.colors.gray100};
    border-top: none;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 18px;
    font-weight: 500
`

const MemberScheduleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(17, minmax(0, 1fr));
  min-width: 1000px;
  border: 2px solid ${props => props.theme.colors.gray100};
  border-bottom: none;

  .column {
    grid-column: span 2;

    &.members {
      grid-column: span 3;

      .item {
        border-left: none;
        padding-left: 10px;
        padding-right: 10px;
      }

      .header {
        display: flex;
        justify-content: flex-start;

        h4 {
          font-size: 12px;
          font-weight: bold;
          color: ${props => props.theme.palette.dashboard.textDark};
        }

        .label {
          font-size: 12px;
          color: ${props => props.theme.palette.dashboard.textPrimary};
          margin-right: 5px;
          font-weight: normal;
        }
      }
    }

    &.today {

      h3, .day {
        color: ${props => props.theme.colors.blue50} !important;
      }

      .item:not(.date-label) {
      }
    }

    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      border: 2px solid ${props => props.theme.colors.gray100};
      border-top: none;
      border-right: none;
      cursor: pointer;
      padding: 3px;
      overflow-y: auto;
      &:hover {
        background-color: #f4f6f9;
      }

      &.date-label {
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 30px;
          line-height: 36px;
          margin-right: 25px;
          color: ${props => props.theme.palette.dashboard.textDark};
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          span {
            font-size: 12px;

            &.month {
              color: ${props => props.theme.colors.gray200};
            }
          }
        }
      }

      &.header {
        font-weight: bold;
      }
    }
  }
`

const WeekScheduleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(17, minmax(0, 1fr));
  min-width: 1000px;
  border: 2px solid ${props => props.theme.colors.gray100};

  .column {
    grid-column: span 2;

    &.members {
      grid-column: span 3;

      .item {
        h4 {
          text-align: center;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .time {
        position: relative;
        align-items: flex-start;
      }
    }

    &.today {

      h3, .day {
        color: ${props => props.theme.colors.blue50} !important;
      }

      .item:not(.date-label) {
        background-color: #f4f6f9;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      border: 2px solid ${props => props.theme.colors.gray100};
      border-top: none;
      border-left: none;

      &.date-label {
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 30px;
          line-height: 36px;
          margin-right: 25px;
          color: ${props => props.theme.palette.dashboard.textDark};
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          span {
            font-size: 12px;

            &.month {
              color: ${props => props.theme.colors.gray200};
            }
          }
        }
      }

      &.header {
        font-weight: bold;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &:last-child {
      .item {
        border-right: none;
      }
    }
  }
`

export { Container, Tag, MemberScheduleContainer, WeekScheduleContainer, noInfo }