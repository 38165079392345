import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getMembers } from "../../../../store/selectors";
import MemberSelector from "../../../Common/MemberSelector";

const MembersTab = (props) => {
  const members = useSelector(getMembers);

  const managers = useMemo(() => {
    return members.filter((member) => props.values.teams.includes(member.team) && member.role === 'manager');
  }, [props.values.teams]);

  const developers = useMemo(() => {
    return members.filter((member) => props.values.teams.includes(member.team) && member.role === 'member');
  }, [props.values.teams]);

  return (
    <>
      <MemberSelector
        members={managers}
        label="MANAGERS"
        description="Oversees and manages the project"
        { ...props }
      />
      <MemberSelector
        members={developers}
        label="DEVELOPERS"
        description="Works on the project, will not see other users (most common)"
        { ...props } />
      <MemberSelector
        members={members}
        label="VIEWERS"
        description="Can view team reports for this project"
        { ...props } />
    </>
  );
}

export default MembersTab;
