import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setInvoice, setInvoices } from "../../../../store/actions/invoice.action";
import { getInvoices } from "../../../../store/selectors/invoice.selector";
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router'
import { saveAs } from 'file-saver';
import { CSVService } from "../../../../service/csv.service";
import { PdfService } from "../../../../service/pdf.service";
import { Box } from '@material-ui/core'
import DateRange from '../../../../components/Dashboard/DateRange'
import Button from '../../../../components/Dashboard/Button'
import Select, { Option } from '../../../../components/Dashboard/Select'
import InvoiceTable from './InvoiceTable'
import clsx from 'clsx'
import moment from 'moment'
import * as S from './styles'
import * as InvoiceApi from "../../../../apis/invoice.api";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  select: {
    "width": '25%',
    '& .label': {
      fontSize: 20
    }
  },
  dateRange: {
    '& .show-date': {
      width: 500
    }
  },
  button: {
    padding: '7px 22px !important',
    borderColor: '#18d06b !important',
    backgroundColor: '#18d06b !important',
    marginLeft: 30,
  },
  export: {
    width: 100,
    marginLeft: 20
  }
}));

export const statusOptions = [
  {
    value: 'invoiced',
    label: 'Invoiced'
  },
  {
    value: 'uninvoiced',
    label: 'Uninvoiced'
  },
  {
    value: 'paid',
    label: 'Paid'
  },
  {
    value: 'not_billable',
    label: 'Not billable'
  },
]

const Invoices = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const invoices = useSelector(getInvoices);
  const [statusInvoice, setStatusInvoice ] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  });

  useEffect(() => {
    InvoiceApi.getInvoices(dateRange).then((res) => dispatch(setInvoices(res)));
    dispatch(setInvoice(null));
  }, [dateRange]);

  const handleStatusApply = async () => {
    if (selectedRows.length > 0) {
      await InvoiceApi.updateStatus({
        selectedRows,
        status: statusInvoice,
      });
      InvoiceApi.getInvoices(dateRange).then((res) => dispatch(setInvoices(res)));
    }
  };

  const handleOutStanding = () => {
    return _.sum(_.map(invoices, 'total')).toFixed(2).toString().concat('$');
  };

  const handlePaid = () => {
    const data = _.filter( invoices, item => item.status === 'paid');
    return _.sum(_.map(data, 'grandTotal')).toFixed(2).toString().concat('$');
  };

  const excelData = useMemo(() => {
    return invoices.map((invoice: any) => (
      {
        ...invoice,
        payer: invoice.payer.name,
        client: invoice.client.name,
        outStanding: handleOutStanding(),
        paid: handlePaid(),
      }
    ))
  }, [invoices]);

  const handleExportExcel = (value: string) => {
    switch (value) {
      case 'csv': {
        return CSVService.export({ excelData }, 'csv').then((res) => {
          saveAs(res, `invoice.csv`);
        });
      }
      case 'pdf': {
        return PdfService.export({
          elementId: 'print-pdf',
          name: 'invoice'
        });
      }
      default:
        return;
    }
  };

  return (
    <S.Container>
      <Box mb={2}>
        <h1>User invoices</h1>
      </Box>
      <Box className='date-range' display='flex' justifyContent='space-between'>
        <DateRange
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <Box display='flex'>
          <S.Info>
            <span className='label'>Outstanding</span>
            <h5 className='value'>{handleOutStanding()}</h5>
          </S.Info>
          <S.Info ml={2}>
            <span className='label'>Paid</span>
            <h5 className='value paid'>{handlePaid()}</h5>
          </S.Info>
        </Box>
      </Box>
      <Box className='status' display='flex' mt={2} alignItems='flex-end'>
        <Select
          label='Status'
          placeholder='select a payment status'
          className={classes.select}
          onChange={(e) => setStatusInvoice(e)}
        >
          {
            statusOptions.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))
          }
        </Select>
        <Button
          className={clsx(classes.button, 'primary-contained')}
          onClick={handleStatusApply}
        >
          Apply
        </Button>
      </Box>
      <Box className='new-invoice' display='flex' justifyContent='flex-end' mt={2}>
        <Button className='primary-contained' onClick={() => history.push('/dashboard/financials/invoices/new')}>New invoice</Button>
        <Select
          placeholder="Export"
          className={classes.export}
          onChange={(e) => handleExportExcel(e)}
        >
          <Option value="csv">CSV</Option>
          <Option value="pdf">PDF</Option>
        </Select>
      </Box>
      <Box mt={2} id='print-pdf'>
        <InvoiceTable
          setSelectedRows={setSelectedRows}
          dateRange={dateRange}
        />
      </Box>
    </S.Container>
  )
}

export default Invoices;
