import styled, { css } from 'styled-components'
import { AppPage } from '../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  max-width: 1400px;
  margin: 0 auto;

  .divider {
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.colors.gray100};
    margin: 15px 0;
  }

  button:disabled {
    opacity: 0.5;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    button {
      width: 100%;
    }
  }
`

const NotificationItem = styled.div<{ isRead?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.gray100};
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  margin-top: 10px;

  .status {
    width: 16px;
    height: 16px;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 50%;
  }

  .content {
    flex: 1;
    padding: 0 10px;
  }

  .time {
    color: ${props => props.theme.palette.dashboard.textPrimary};
    margin-right: 2rem;
  }

  ${props => props.isRead && css`
    background-color: #f2f3f6;

    .status {
      background-color: ${props => props.theme.colors.green100};

      svg {
        width: 15px;
        height: 15px;
        color: white;
      }
    }

    .content {
      opacity: 0.5;
    }
  `}
`

export { Container, NotificationItem }