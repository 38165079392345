import React from "react";
import { Box } from "@material-ui/core";
import { FormikProps } from "formik";
import { INotificationProps } from "./index";
import TextArea from "../../Common/TextArea";
import * as S from "./styles";

const DetailNote = ({ ...props }: FormikProps<INotificationProps>) => {
  return (
    <Box>
      <S.FormControl>
        <span className="label">
          <span className="switch-label">
            Notification
          </span>
        </span>
        <TextArea
          name="detail"
          placeholder="Add notifications in detail"
          value={props.values.detail}
          onChange={(e) => props.setFieldValue('detail', e.target.value)}
          error={props.errors.detail || props.touched.detail ? props.errors.detail : ""}
        />
      </S.FormControl>
    </Box>
  )
}
export default DetailNote;
